import { FieldErrors } from "react-hook-form";
import { promocodeFormInputs } from "../../../types";

export interface IFormErrors {
  [key: string]: {
    type?: string;
    message?: string;
  };
}

export interface IPromocodeExpiryDate {
  errors: FieldErrors<any>;
  start_date: number;
  end_date: number;
  control: any;
  header?: string;
  setValue: (field: promocodeFormInputs, value: any) => void;
}

export const dateInputStyle = {
  height: "60px",
  width: "100%",
  borderRadius: "10px",
  paddingLeft: "40px",
};
import { TimePicker } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import styles from "./styles.module.sass";
import "./styles.less";
import moment from "moment";
import { getWeeksInAMonth } from "../../../../helpers";
import { monthOptions, useTimeTrigger } from "../../../../hooks";
import { daysOptions } from "./options";
import { ControllableGenericSelector } from "../../../../pages/TargetingTool/CampaignsList/GenericMultiSelector";
import {
  GenericOption,
  SelectionMode,
} from "../../../../pages/TargetingTool/CampaignsList/GenericMultiSelector/types";

const TriggerSettings: React.FC<{ index: number }> = ({ index }) => {
  const { control, watchers, setValue, weeksOptions, weekdaysOptions } =
    useTimeTrigger(index);
  console.log(watchers.time_triggers[index].week_day);
  return (
    <div key={index} className={styles.wrapper}>
      <ControllableGenericSelector
        control={control}
        name={`time_triggers.${index}.month`}
        selectOptions={{
          label: "Mounth:",
          mode: SelectionMode.MULTI,
          options: (monthOptions as unknown as GenericOption[]).map((item) => ({
            ...item,
            value: item.value.toString(),
          })),
          updateOptions: (options) => {
            setValue(
              `time_triggers.${index}.month`,
              options.map((item) => parseInt(item))
            );

            if (!options.length) setValue(`time_triggers.${index}.week`, []);
            options
              .map((item) => parseInt(item))
              .forEach((item) => {
                if (!watchers.time_triggers[index].month.includes(item)) {
                  setValue(
                    `time_triggers.${index}.week`,
                    watchers.time_triggers[index].week.filter(
                      (element: number) =>
                        getWeeksInAMonth({
                          months: watchers.time_triggers[index].month,
                        }).includes(element)
                    )
                  );
                }
              });
          },
          value: watchers.time_triggers[index].month.map((item: number) =>
            item.toString()
          ),
          targetStyles: styles.triggrer_styles,
          disabled: false,
        }}
      />

      <div className={styles.separator} />
      <ControllableGenericSelector
        control={control}
        name={`time_triggers.${index}.week`}
        selectOptions={{
          label: "Week:",
          mode: SelectionMode.MULTI,
          options: (weeksOptions as unknown as GenericOption[]).map((item) => ({
            ...item,
            value: item.value.toString(),
          })),
          updateOptions: (options) => {
            setValue(
              `time_triggers.${index}.week`,
              options.map((item) => parseInt(item))
            );
          },
          value: watchers.time_triggers[index].week.map((item: number) =>
            item.toString()
          ),
          targetStyles: styles.triggrer_styles,
          disabled: !watchers.time_triggers[index].month?.length,
        }}
      />
      <div className={styles.separator} />
      <ControllableGenericSelector
        control={control}
        name={`time_triggers.${index}.week_day`}
        selectOptions={{
          label: "Day:",
          mode: SelectionMode.MULTI,
          options: (
            (weekdaysOptions.length > 0
              ? weekdaysOptions
              : daysOptions) as unknown as GenericOption[]
          ).map((item) => ({ ...item, value: item.value.toString() })),
          updateOptions: (options) => {
            setValue(
              `time_triggers.${index}.week_day`,
              options.map((item) => parseInt(item))
            );
          },
          value: watchers.time_triggers[index].week_day.map((item: number) =>
            item.toString()
          ),
          targetStyles: styles.triggrer_styles,
          disabled:
            !watchers.time_triggers[index].week?.length ||
            !watchers.time_triggers[index].month?.length,
        }}
      />
      <div className={styles.separator} />
      <Controller
        control={control}
        name={`time_triggers.${index}.time`}
        render={({ field: { onChange } }) => {
          return (
            <div className={styles.messageOnWrapper}>
              <span className={styles.messageOnTitle}>Message on:</span>
              <TimePicker
                use12Hours
                placeholder="12:00 PM"
                clearIcon={false}
                onChange={(value) => {
                  onChange(Number(moment(value).format("x")));
                }}
                value={moment(Number(watchers.time_triggers[index].time))}
                className="message-on-time-picker"
                format="h:mm A"
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default React.memo(TriggerSettings);

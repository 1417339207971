import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./styles.module.sass";
import "./styles.less";
import { CampaignFormValues } from "../../../../types";
import {
  GenericOption,
  SelectionMode,
} from "../../../../pages/TargetingTool/CampaignsList/GenericMultiSelector/types";
import { ControllableGenericSelector } from "../../../../pages/TargetingTool/CampaignsList/GenericMultiSelector";

const UserType: React.FC<{ index: number }> = ({ index }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<CampaignFormValues>();
  const watchers = watch();
  const userTypeOptions = useMemo(
    () => [
      {
        value: "online_app_user",
        label: "Ordering users — (1,000,000 users)",
      },
      {
        value: "offline_app_user",
        label: "Offline Users — (1,000,000 users)",
      },
      {
        value: "temp_user",
        label: "Temp App User — (1,000,000 users)",
      },
    ],
    []
  );
  return (
    <div className={styles.wrapper}>
      <h3 style={{ fontWeight: "700", padding: "5px" }}>Users type</h3>
      <ControllableGenericSelector
        control={control}
        name={`segment.${index}.user_type`}
        selectOptions={{
          // label: "Users type",
          mode: SelectionMode.MULTI,
          options: (userTypeOptions as unknown as GenericOption[]).map(
            (item) => ({
              ...item,
              value: item.value.toString(),
            })
          ),
          updateOptions: (options) => {
            setValue(`segment.${index}.user_type`, options);
          },
          value: watchers.segment[index].user_type.map((item) => item),
          targetStyles: styles.triggrer_styles,
          disabled: false,
        }}
      />
      {errors.segment && errors.segment[index]?.user_type && (
        <span className={styles.userTypeError}>
          {errors.segment[index]?.user_type?.message}
        </span>
      )}
    </div>
  );
};

export default UserType;
import { Input, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import IconPlus from "../../../assets/images/IconPlus";
import images from "../../../assets/images/images";
import { useGetTemplatesQuery } from "../../../store/apis/templateSelection";
import GenericMultiSelector from "../CampaignsList/GenericMultiSelector";
import {
  GenericOption,
  SelectionMode,
} from "../CampaignsList/GenericMultiSelector/types";
import { columns, Template } from "./columns";
import "./style.less";
import styles from "./styles.module.sass";
export interface Filters {
  page: number;
  size: number;
  filter?: string;
  sort_order?: "asc" | "desc";
}

export interface TemplateInfo {
  id: string;
  ar_message: string;
  en_message: string;
  ar_title: string;
  en_title: string;
}
export const CommunicationTemplate: React.FC = () => {
  const TagsOptions: GenericOption[] = [
    { value: "SMS", label: "SMS" },
    { value: "Notification", label: "Notification" },
    { value: "Whatsapp", label: "Whatsapp" },
  ];
  const navigate = useNavigate();
  const { data: templates, isLoading } = useGetTemplatesQuery();
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const createNewTemplate = () => {
    navigate("create");
  };
  const templatesList = useMemo(() => {
    return (templates || [])
      .map(
        ({ id, en_title, ar_title }: TemplateInfo): Template => ({
          id,
          title: en_title ? en_title : ar_title,
          segment_use_time: 2,
          channel_tags: ["SMS", "Whatsapp"],
        })
      )
      .filter((template: Template) => {
        return (
          (template.title.includes(filterSearchText) ||
            template.id.toString().includes(filterSearchText)) &&
          (template.channel_tags.every((item) => tagFilter.includes(item)) ||
            !tagFilter.length)
        );
      });
  }, [templates, filterSearchText, tagFilter]);

  const applySearchFilter = (e: any) => {
    setFilterSearchText(e.target.value);
  };
  return (
    <div className={styles.main}>
      <div className={styles.headerWrapper}>
        <span className={styles.headerTitle}>Communication Template</span>
        <button
          onClick={createNewTemplate}
          className={styles.createTemplateWrapper}
        >
          <IconPlus stroke="#fff" height="24px" width="24px" />
          <span className={styles.createTemplateTitle}>Create Template</span>
        </button>
      </div>
      <div className={styles.template_list_wrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.tableHead}>
            <div className={styles.tableHeadRowOne}>
              <div className={styles.title}>
                {`Communication Templates (${templatesList.length})`}
              </div>

              <div className={styles.searchAndActions}>
                <div className={styles.search}>
                  <Input
                    className="insights-header-search-input"
                    size="small"
                    placeholder={"Search by customer name, phone number..."}
                    value={filterSearchText}
                    onPressEnter={applySearchFilter}
                    onChange={(e) => {
                      setFilterSearchText(e.target.value);
                    }}
                    prefix={<img src={images.search} />}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 20px 1.5rem",
              }}
            >
              <GenericMultiSelector
                mode={SelectionMode.MULTI}
                updateOptions={setTagFilter}
                options={TagsOptions}
                label={"Channel"}
                value={tagFilter}
                disabled={false}
              />
            </div>
          </div>
          <div className={styles.tableBody} id="customer-list">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <BounceLoader size={60} color={"#f09440"} />
              </div>
            ) : (
              <Table
                columns={columns}
                rowClassName={"insights-table-row"}
                pagination={false}
                dataSource={templatesList}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationTemplate;

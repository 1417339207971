import React, { useCallback, useMemo, useState } from "react";
import styles from "./styles.module.sass";
import images, { ChevronDown } from "../../../../assets/images/images";
import { Dropdown } from "../../../../components";
import {
  ControllableGenericSelectorProps,
  GenericMultiSelectorProps,
  GenericOption,
  SelectionMode,
} from "./types";
import { Controller } from "react-hook-form";

export const GenericMultiSelector: React.FC<GenericMultiSelectorProps> = ({
  options,
  updateOptions,
  label,
  mode,
  optionStyles,
  targetStyles,
  value,
  disabled,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const allSelected = useMemo(() => {
    return options.length === value.length;
  }, [options, value]);
  const [search, setSearch] = useState("");
  const selectesOptions = useMemo(() => {
    const labels: string[] = value.reduce((acc: string[], item: string) => {
      const option = options.find((option) => option.value === item);
      return option ? [...acc, option.label] : acc;
    }, []);
    const full_length = options.length;
    switch (labels.length) {
      case 0:
        return "Select an option....";
      case 1:
        return labels[0];
      case full_length:
        return "All";
      default:
        return `${labels.length} Selecetd`;
    }
  }, [value, options]);

  const toggleSelection = useCallback(
    (item: GenericOption) => {
      const selectedIndex = value.findIndex((option) => option == item.value);
      updateOptions(
        mode === SelectionMode.MULTI
          ? selectedIndex > -1
            ? [...value.filter((value) => value !== item.value)]
            : [...value, item.value]
          : [item.value]
      );
    },
    [value]
  );

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [options, search]);

  const toggleAll = useCallback(() => {
    updateOptions(allSelected ? [] : options.map((item) => item.value));
  }, [allSelected, updateOptions, options]);

  return (
    <Dropdown
      isOpen={menuOpen}
      onClose={() => {
        setMenuOpen(false);
      }}
      target={
        <div
          // className={}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {label && (
            <span
              style={{
                fontSize: "16px",
                fontWeight: "700",
                padding: "10px",
              }}
            >
              {label}
            </span>
          )}
          <div
            className={`${targetStyles ? targetStyles : styles.dropdownBtn} ${
              disabled ? styles.disabled : ""
            } `}
            onClick={
              !disabled
                ? () => setMenuOpen((prev) => !prev)
                : () => {
                    console.log("disabled");
                  }
            }
          >
            <div className={styles.dropdownLabel}>
              <span className={styles.title}>{selectesOptions}</span>
            </div>
            <ChevronDown
              width={24}
              height={24}
              className={menuOpen ? styles.dropdownOpened : ""}
              style={{ marginLeft: "4px" }}
              stroke={"#503E9D"}
            />
          </div>
        </div>
      }
    >
      <div
        className={styles.dropdownContainer}
        onBlur={() => {
          setMenuOpen(false);
        }}
      >
        <div
          className={`${optionStyles ? optionStyles : styles.search_container}`}
        >
          <div className={styles.search}>
            <input
              placeholder={"Search options...."}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          onClick={() => {
            mode === SelectionMode.MULTI ? toggleAll() : updateOptions([]);
          }}
          className={`${optionStyles ? optionStyles : styles.dropdownWrapper}`}
        >
          {mode === SelectionMode.MULTI && (
            <span
              className={`${styles.check} ${value.length ? styles.active : ""}`}
            >
              {!allSelected && value.length ? "-" : ""}
              {allSelected && <img src={images.check} alt="" />}
            </span>
          )}
          <span className={styles.itemDropdropLabel}>
            {mode === SelectionMode.MULTI ? "All" : "None"}
          </span>
        </div>
        {filteredOptions.map((element) => (
          <div
            onClick={() => {
              toggleSelection(element);
            }}
            key={element.value}
            className={`${
              optionStyles ? optionStyles : styles.dropdownWrapper
            } ${
              mode === SelectionMode.SINGLE && value.includes(element.value)
                ? styles.active
                : ""
            }`}
          >
            {mode === SelectionMode.MULTI && (
              <span
                className={`${styles.check} ${
                  value.includes(element.value) ? styles.active : ""
                }`}
              >
                {value.includes(element.value) && (
                  <img src={images.check} alt="" />
                )}
              </span>
            )}
            <span className={styles.itemDropdropLabel}>{element.label}</span>
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default GenericMultiSelector;

export const ControllableGenericSelector: React.FC<
  ControllableGenericSelectorProps
> = ({ control, selectOptions, name }: ControllableGenericSelectorProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <GenericMultiSelector
            {...selectOptions}
            options={selectOptions.options}
            label={selectOptions.label}
            value={selectOptions.value}
            updateOptions={(values: string[]) => {
              selectOptions.updateOptions(values);
            }}
            mode={selectOptions.mode}
          />
        );
      }}
    />
  );
};

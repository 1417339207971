import { Input, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconPlus from "../../../assets/images/IconPlus";
import images from "../../../assets/images/images";
import { columns } from "./columns";
import "./style.less";
import styles from "./styles.module.sass";
import { GenericMultiSelector } from "./GenericMultiSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { getCampaignListRequest } from "../../../store/thunks/getCampaignList";
import { useSelect } from "../../../hooks";
import { GenericOption, SelectionMode } from "./GenericMultiSelector/types";
import { Filters } from "./types";
import InfiniteScroll from "react-infinite-scroll-component";
import { BounceLoader } from "react-spinners";
import { getCampaignListCountRequest } from "../../../store/thunks/getCampaignListCount";

export const CampaignsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { campaigns, isLoading, count } = useSelect(
    (state) => state.campaignsListReducer
  );
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [filters, setFilters] = useState<Filters>({
    page: 0,
    size: 20,
  });
  const statusesOptions: GenericOption[] = [
    { value: "upcoming", label: "Upcoming" },
    { value: "failed", label: "Failed" },
    { value: "running", label: "Running" },
    { value: "finished", label: "Finished" },
    {
      value: "recurring",
      label: "Recurring",
    },
  ];
  const [timeTriggers] = useState<string[]>(
    statusesOptions.map((item) => item.value)
  );
  const [statuses, setStatuses] = useState<string[]>(
    statusesOptions.map((item) => item.value)
  );
  const [singleStatuses, setSingleStatuses] = useState<string[]>([
    statusesOptions[0].value,
  ]);

  const createNewTemplate = () => {
    navigate("/targeting-tool/target-campaigns");
  };
  const applySearchFilter = (e: any) => {
    setFilterSearchText(e.target.value);
  };
  useEffect(() => {
    dispatch(getCampaignListRequest({ filters }));
    dispatch(getCampaignListCountRequest({ filters }));
  }, [filters]);

  useEffect(() => {
    setFilters({
      states: statuses,
      time_trigger: timeTriggers,
      title: filterSearchText ? filterSearchText : undefined,
      page: 0,
      size: 20,
    });
  }, [statuses, timeTriggers, searchText]);
  const handleNext = () => {
    setFilters({
      ...filters,
      page: ++filters.page,
    });
  };
  const hasMore = useMemo(() => {
    return campaigns.length < count;
  }, [campaigns, count]);
  return (
    <div className={styles.main}>
      <div className={styles.headerWrapper}>
        <span className={styles.headerTitle}>Target Campaigns</span>
        <button
          onClick={createNewTemplate}
          className={styles.createTemplateWrapper}
        >
          <IconPlus stroke="#fff" height="24px" width="24px" />
          <span className={styles.createTemplateTitle}>
            Create New Campaign
          </span>
        </button>
      </div>
      <div className={styles.template_list_wrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.tableHead}>
            <div className={styles.tableHeadRowOne}>
              <div
                className={styles.title}
              >{`Total Campaigns (${campaigns.length})`}</div>

              <div className={styles.searchAndActions}>
                <div className={styles.search}>
                  <Input
                    className="insights-header-search-input"
                    size="small"
                    placeholder={"Search by customer name, phone number..."}
                    value={filterSearchText}
                    onPressEnter={applySearchFilter}
                    onChange={(e) => {
                      setFilterSearchText(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.code === "Enter") {
                        setSearchText(filterSearchText);
                      }
                    }}
                    prefix={<img src={images.search} />}
                  />
                </div>
              </div>
            </div>
            <div className={styles.filtersWrapper}>
              {" "}
              <GenericMultiSelector
                mode={SelectionMode.MULTI}
                updateOptions={setStatuses}
                options={statusesOptions}
                label={"Status"}
                value={statuses}
                disabled={false}
              />
              <GenericMultiSelector
                mode={SelectionMode.SINGLE}
                updateOptions={setSingleStatuses}
                options={statusesOptions}
                label={"Single Status"}
                value={singleStatuses}
                disabled={false}
              />
            </div>
          </div>
          <div className={styles.tableBody} id="customer-list">
            <InfiniteScroll
              style={{ overflow: "scroll" }}
              next={handleNext}
              dataLength={filters.page * filters.size}
              hasMore={hasMore}
              loader={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  {isLoading && <BounceLoader size={60} color={"#f09440"} />}
                </div>
              }
              height={700}
              endMessage={
                <>
                  {
                    <div className={styles.endMessageWrapper}>
                      <span className={styles.endMessageTitle}>
                        {"No more data to be loaded"}
                      </span>
                    </div>
                  }
                </>
              }
              className={styles.wrapper}
            >
              <Table
                columns={columns}
                rowClassName={"insights-table-row"}
                pagination={false}
                dataSource={campaigns}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignsList;

export enum SelectionMode {
  MULTI = "multi",
  SINGLE = "single",
}
export interface GenericOption {
  value: string;
  label: string;
}
export interface GenericMultiSelectorProps {
  options: GenericOption[];
  updateOptions: (value: string[]) => void;
  label?: string;
  targetStyles?: string;
  optionStyles?: string;
  mode: SelectionMode;
  value: string[];
  disabled: boolean;
}

// export interface GenericMultiSelectorProps {
//   options: GenericOption[];
//   updateOptions: (value: string[]) => void;
//   label: string;
//   targetStyles?: string;
//   optionStyles?: string;
//   mode: SelectionMode;
// }

export interface ControllableGenericSelectorProps {
  control: any;
  name: string;
  selectOptions: GenericMultiSelectorProps;
}

import React from "react";
const Menu: React.FC<JSX.IntrinsicElements["div"]> = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        overflow: "hidden",
        marginTop: 8,
        position: "absolute",
        zIndex: 99999999999999,
        left: 0,
        maxHeight: "500px",
        overflowY: "scroll",
        width: "100%",
      }}
      {...props}
    />
  );
};

export default Menu;
